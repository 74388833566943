@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'carousel';
@import 'lightbox';
@import 'typography';
@import './mixins/responsive';

:root {
    --grid-mobile-columns: 4;
    --grid-tablet-columns: 12;
    --grid-widescreen-columns: 14;
    --grid-fullhd-columns: 16;

    --grid-gap-mobile: 0.5rem;
    --grid-gap-tablet: 1rem;
    --grid-gap-desktop: 1.5rem;
    --grid-gap-widescreen: 2rem;
    --grid-gap-fullhd: 1.5rem;

    --grid-columns: var(--grid-mobile-columns);
    --grid-column: calc(
        (100% - ((var(--grid-mobile-columns) - 1) * var(--grid-gap-mobile))) / var(--grid-mobile-columns)
    );
    --grid-gap: var(--grid-gap-mobile);
    --floor-spacing: 4rem;
    --spacing: 3rem;

    @include from-tablet {
        --grid-columns: var(--grid-tablet-columns);
        --grid-column: calc(
            (100% - ((var(--grid-tablet-columns) - 1) * var(--grid-gap-tablet))) / var(--grid-tablet-columns)
        );
        --grid-gap: var(--grid-gap-tablet);
        --floor-spacing: 5rem;
        --spacing: 3.5rem;
    }
    @include from-desktop {
        --grid-column: calc(
            (100% - ((var(--grid-tablet-columns) - 1) * var(--grid-gap-desktop))) / var(--grid-tablet-columns)
        );
        --grid-gap: var(--grid-gap-desktop);
    }
    @include large-desktop {
        --grid-columns: var(--grid-widescreen-columns);
        --grid-column: calc(
            (100% - ((var(--grid-widescreen-columns) - 1) * var(--grid-gap-widescreen))) /
                var(--grid-widescreen-columns)
        );
        --grid-gap: var(--grid-gap-widescreen);
        --floor-spacing: 12.5rem;
        --spacing: 7.5rem;
    }
    @include fullhd {
        --grid-columns: var(--grid-fullhd-columns);
        --grid-column: calc(
            (100% - ((var(--grid-fullhd-columns) - 1) * var(--grid-gap-fullhd))) / var(--grid-fullhd-columns)
        );
        --grid-gap: var(--grid-gap-fullhd);
    }
}

html,
body {
    font-size: 100%;
    font-family: 'Metropolis', sans-serif;

    @include fullhd {
        font-size: 0.8333333333vw;
    }
}

.infoBox {
    transform: translate3d(-50%, 0, 0) !important;
}

.customCursor {
    cursor: none !important;
    * {
        cursor: none !important;
    }
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    background-color: transparent !important;
    color: #141414 !important;
}

#Newsletter_blockWrapper__0yEbd {
	display: none;
}
